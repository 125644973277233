import React, { useState } from 'react';
import { Link } from 'gatsby';
import { HOME, SIGN_IN } from '../../constants/routes';
import Logo from '../../assets/logo.svg';
import SignOutButton from './SignOutButton';
import cn from 'classnames';
import HamburgerIcon from '../../images/HamburgerIcon';
import HeaderSideNav from './HeaderSideNav';

import style from './NavBar.scss';

function NavigationAuth({ authUser }) {
  const [isCalculatorsSidebarVisible, setCalculatorsSidebarVisible] = useState(false);
  const showCalculatorsSidebarMenu = () => {
    if (!isCalculatorsSidebarVisible) {
      setCalculatorsSidebarVisible(true);
    }
  };

  return (
    <div className={style.navbar}>
      <HeaderSideNav
        isOpen={isCalculatorsSidebarVisible}
        onClose={setCalculatorsSidebarVisible}
        authUserId={authUser?.uid}
      />
      <div className={style.inner}>
        <div className={cn('col-auto d-lg-none', style.toggleButton)}>
          <HamburgerIcon onClick={showCalculatorsSidebarMenu} aria-label="Open Side Nav" />
        </div>
        <div className="d-flex">
          <div className={style.logo}>
            <img src={Logo} />
          </div>
          <div className="d-none d-lg-flex align-items-center">
            {authUser ? (
              <>
                <div className={style.link}>
                  <Link className={style.name} to={HOME}>
                    Dashboard
                  </Link>
                </div>
                <div className={style.link}>
                  <Link to={'/paymentSettings'}>Payment settings</Link>
                </div>
                <div className={cn(style.link)}>
                <Link to={'/widgetLiveDemo'}>
                  Live demo
                </Link>
                </div>
              </>
            ) : (
              <div className={style.link}>
                <Link className={style.name} to={HOME}>
                  Home
                </Link>
              </div>
            )}
          </div>
        </div>

        <div className={cn(style.userActions, 'col-auto')}>
          {authUser ? (
            <div className="d-flex align-items-center">
              <div className={style.link}>
                <Link to={'/profile'}>Profile</Link>
              </div>
              <div className={style.link}>
                <SignOutButton />
              </div>
            </div>
          ) : (
            <div className={style.link}>
              <Link to={SIGN_IN}>Sign In</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NavigationAuth;
