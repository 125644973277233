import React, { Component } from 'react';

import Navigation from '../Navigation';
import { getFirebase, getFirebaseSecondary, FirebaseContext } from '../../utils/Firebase';
import withAuthentication from '../../utils/Session/withAuthentication';
import SEO from '../../utils/SEO';

import style from './Layout.scss';

class Layout extends Component {
  state = {
    firebase: null,
  };

  componentDidMount() {
    const app = import('firebase/app');
    const auth = import('firebase/auth');
    const database = import('firebase/database');

    Promise.all([app, auth, database]).then((values) => {
      const firebase = getFirebase(values[0]);
      firebase.secondary = getFirebaseSecondary(values[0]);

      this.setState({ firebase });
    });
  }

  render() {
    return (
      <FirebaseContext.Provider value={this.state.firebase}>
        <AppWithAuthentication {...this.props} />
      </FirebaseContext.Provider>
    );
  }
}

const AppWithAuthentication = withAuthentication(({ hideContent, hideNav, seo, children }) => (
  <div className={style.layout}>
    <SEO {...seo} />
    {!hideNav && <Navigation />}
    {hideNav || hideContent ? children : <div className={style.contentWrapper}>{children}</div>}
  </div>
));

export default Layout;
