import React, { useCallback, useState } from 'react';

import cn from 'classnames';
import { Link } from 'gatsby';

import { HOME } from '../../constants/routes';
import CloseIcon from '../../images/CloseIcon';
import SideNav from '../SideNav/SideNav';

import style from './HeaderSideNav.scss';

export default function HeaderSideNav({
  isOpen,
  onClose,
  authUserId,
}: {
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  authUserId: string;
}) {
  const handleSidenavClose = useCallback(() => {
    if (isOpen) {
      onClose(false);
    }
  }, [isOpen]);

  const [subNavIsShown, setSubNavIsShown] = useState(false);

  React.useEffect(() => {
    return () => {
      if (subNavIsShown) {
        setSubNavIsShown(false);
      }
    };
  }, []);

  return (
    <SideNav
      isOpen={isOpen}
      onClose={handleSidenavClose}
      className={cn({ [style.sideNavOpen]: subNavIsShown }, style.sideNavWrapper)}
    >
      <button
        className={style.closeButton}
        onClick={handleSidenavClose}
        aria-label="Close Side Nav"
      >
        <CloseIcon />
      </button>
      <div className={style.sideNavLinks}>
        <Link activeClassName={style.active} to={HOME}>
          Dashboard
        </Link>
        <Link activeClassName={style.active} to="/paymentSettings">
          Payment settings
        </Link>
        <Link activeClassName={style.active} to={'/widgetLiveDemo'}>
          Live demo
        </Link>
      </div>
    </SideNav>
  );
}
