import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const CloseIcon = (props: IIconProps) => {
  const { width = 48, height = 48, className } = props;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 18L18 30M18 18l12 12"
        stroke="#111"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(CloseIcon);
