import React from 'react';

import Button from '../../components/Buttons/PrimaryButton';

import style from './EmailConfirmation.scss';

interface IEmailConfirmationProps {
  isEmailSent: boolean;
  onSendEmailVerification: () => void;
}

export const EmailConfirmation = (props: IEmailConfirmationProps) => {
  const { isEmailSent, onSendEmailVerification } = props;

  return (
    <div className={`container ${style.container}`}>
      <div className="row justify-content-center">
        <div className={`col-12 col-md-6 ${style.content}`}>
          {isEmailSent ? (
            <>
              <h1>Confirmation email sent</h1>
              <p>
                Please check your email (spam folder included) for a confirmation email. Refresh
                this page once you confirmed your email.
              </p>
            </>
          ) : (
            <>
              <h1>Verify your email</h1>
              <p>
                Please check your email (spam folder included) for a confirmation email. Click the
                button below to resend it
              </p>
            </>
          )}
        </div>
      </div>
      <div className="row justify-content-center">
        <div className={`col-12 col-md-6 ${style.content}`}>
          <Button onClick={onSendEmailVerification} isDisabled={isEmailSent}>
            Resend confirmation email
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmation;
