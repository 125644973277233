import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';

import { navigate } from 'gatsby';

import config from '../../../firebaseConfig';
import configSecondary from '../../../firebaseConfigSecondary';

class Firebase {
  constructor() {
    require('firebase/firestore');

    try {
      // Initialize Firebase
      app.initializeApp(config);
    } catch (err) {
      // we skip the "already exists" message which is
      // not an actual error when we're hot-reloading
      if (!/already exists/.test(err.message)) {
        console.error('Firebase initialization error', err.stack);
      }
    }

    /* Helper */
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.database();
    this.firestore = app.firestore();
    this.functions = app.functions();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => {
    this.auth.signOut();
    navigate('/');
  };

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: window.location.origin,
    });

  doPasswordUpdate = (password) => this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // this.user(authUser.uid)
        // .get()
        // .then(snapshot => {
        // const dbUser = snapshot.data();

        // merge auth and db user
        authUser = {
          uid: authUser.uid,
          email: authUser.email,
          emailVerified: authUser.emailVerified,
          providerData: authUser.providerData,
          // ...dbUser,
        };

        next(authUser);
        // });
      } else {
        fallback();
      }
    });

  // *** User API ***

  user = (uid) => null; // this.db.ref(`users/${uid}`).once('value');

  users = () => null; // this.db.ref('users').once('value');

  posts = () => this.db.ref('blacklist');

  blacklist = () => this.db.ref('blacklist');

  whitelist = () => this.db.ref('whitelist');

  firestore = this.firestore;

  post = (post) => this.db.ref('blacklist'); //.where('slug', '==', post.slug);
}

class FirebaseSecondary {
  secondaryApp;
  constructor() {
    require('firebase/firestore');

    try {
      // Initialize Firebase
      this.secondaryApp = app.initializeApp(configSecondary, 'secondary');
    } catch (err) {
      // we skip the "already exists" message which is
      // not an actual error when we're hot-reloading
      if (!/already exists/.test(err.message)) {
        console.error('Firebase initialization error', err.stack);
      }
    }

    /* Helper */
    this.emailAuthProvider = this.secondaryApp.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = this.secondaryApp.auth();
    this.db = this.secondaryApp.database();
    this.firestore = this.secondaryApp.firestore();
    this.functions = this.secondaryApp.functions();
  }

  // *** User API ***
  comments = () => this.firestore.collection('comments').get();
  firestore = this.firestore;
  users = () => this.db.ref('users');
  calculations = () => this.db.ref('calculations');
}

let firebase;
let firebaseSecondary;

export function getFirebase(app, auth, database) {
  if (!firebase) {
    firebase = new Firebase(app, auth, database);
  }

  return firebase;
}

export function getFirebaseSecondary(app, auth, database) {
  if (!firebaseSecondary) {
    firebaseSecondary = new FirebaseSecondary(app, auth, database);
  }

  return firebaseSecondary;
}

export default getFirebase;
