import React from 'react';

import { withFirebase } from '../../utils/Firebase';

import style from './SignoutButton.scss';

const SignOutButton = ({ firebase }) => (
  <div className={style.signoutBtn} onClick={firebase ? firebase.doSignOut : () => {}}>
    Sign Out
  </div>
);

export default withFirebase(SignOutButton);
