import React from 'react';

import Backdrop from '@trendcapital/loan-calculators-widget/src/components/Backdrop';
import cn from 'classnames';

import style from './SideNav.scss';

// export interface ISideNavProps {
//   onClose: () => void;
//   className?: string;
//   children: React.ReactNode;
//   isOpen: boolean;
// }

export default React.memo(function StyledSideNav(props) {
  const { children, className, isOpen = false, onClose } = props;

  return (
    <Backdrop className={style.backdrop} onOutsideClick={onClose} isOpen={isOpen}>
      <div className={cn(className, style.sideNav, { [style.isOpen]: isOpen })}>{children}</div>
    </Backdrop>
  );
});
