import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

const HamburgerIcon = (props: IIconProps) => {
  const { width = 24, height = 24, className, onClick } = props;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path stroke="#000" strokeWidth="2" strokeLinecap="square" d="M2 6h20M2 12.5h10M2 19h16" />
      </g>
    </svg>
  );
};

export default React.memo(HamburgerIcon);
