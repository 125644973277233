import React from 'react';

export const FirebaseContext = React.createContext(null);

export const withFirebase = (Component) => (props) => {
  const { children, ...restProps } = props;

  return (
    <FirebaseContext.Consumer>
      {(firebase) => (
        <Component {...restProps} firebase={firebase}>
          {children}
        </Component>
      )}
    </FirebaseContext.Consumer>
  );
};

export default FirebaseContext;
